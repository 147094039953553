





















































































import axios from 'axios';
import {
  ref,
  defineComponent,
  reactive,
  onMounted,
} from '@vue/composition-api';
import { vRequired, vEmail, vPhoneRules } from 'monkey-validates-form';
export default defineComponent({
  props: {
    inputs: Object,
  },
  setup(props, { emit }) {
    const valid = ref(false);
    const formContacto = ref(null);
    const formdata = new FormData();
    const places = props.inputs;
    const state: any = reactive({
      form: 'contacto',
      name: '',
      phone: '',
      email: '',
      message: '',
    });
    const respuesta = reactive({
      texto: '',
      type: 'error',
      visible: false,
      spinner: false,
    });
    const enviar = (e: Event) => {
      e.preventDefault();
      const data = [
        { name: 'nombre', value: state.name },
        { name: 'numero', value: state.phone },
        { name: 'email', value: state.email },
        { name: 'message', value: state.message },
      ];
      (window as any).RdIntegration.post(data);
      for (const key in state) {
        formdata.append(key, state[key]);
      }
      if ((formContacto.value as any).validate()) {
        respuesta.spinner = true;
        const formdata = new FormData();
        const config = {
          headers: { 'Content-Type': 'multipart/form-data' },
        };
        for (const key in state) {
          formdata.append(key, state[key]);
        }
        axios
          .post(
            'https://monkeyplusbc.com/assets/php/sendmail.php',
            formdata,
            config,
          )
          .then((response) => {
            (formContacto.value as any).reset();
            respuesta.texto = response.data.mensaje;
            respuesta.type = 'success';
          })
          .catch((error) => {
            respuesta.texto = 'Error al enviar el mensaje';
            respuesta.type = 'error';
          })
          .finally(() => {
            respuesta.visible = true;
            respuesta.spinner = false;
          });
      }
    };
    const extens = () => {
      if (
        state.email.includes('gmail') ||
        state.email.includes('hotmail') ||
        state.email.includes('yahoo') ||
        state.email.includes('outlook')
      ) {
        return 'Por favor completa con tu correo corporativo';
      } else {
        return true;
      }
    };
    // onMounted(() => {
    //   console.log(page.value);
    // });
    return {
      vRequired,
      vEmail,
      vPhoneRules,
      formContacto,
      state,
      respuesta,
      enviar,
      valid,
      places,
      extens,
    };
  },
});
